'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  hideWeek: true,
  /**
   * Welcome variant
   * 1 - Welcome message with usage
   * 2 - Welcome message with mandate and meter payment options
   */
  title: 'Overzicht',
  welcomeVariant: 2,
  showWorkSchedule: true,
  faqURL: 'https://mijndomeinenergie.zendesk.com/hc/nl',
  priceCapURL: 'https://mijndomeinenergie.zendesk.com/hc/nl/articles/7732048075421-Prijsplafond-2023'
};