"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    // styles
    barColor: '#6b2fad',
    barHoverColor: '#7232b9',
    lineColor: '#95a4ac',
    axisLabelColor: '#285685',
    legendaInformation: ``,
};
