'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Nieuw wachtwoord aanvragen',
    body: 'Vul hier je e-mailadres in en stel via jouw mailbox opnieuw een wachtwoord in.',
    inputLabel: 'E-mailadres',
    inputPlaceholder: 'naam@domein.nl',
    submit: 'Verstuur aanvraag',
    backButtonLabel: 'Laat maar,',
    backButtonText: 'ga terug naar inloggen.',
    showBackButton: true
};