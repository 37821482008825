"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host {
        --hv-input_-_input_-_max-width: auto;
    }

    ::slotted(input) {
        padding-top: 17px;
        padding-bottom: 17px;
    }

    ::slotted(label) {
        font-family: var(--primary-font-family);
        font-size: 13px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: -0.1px;
        font-weight: 400;
        color: #646f79;
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
