'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    appTitle: 'Mijndomein Energie',
    logoRedirect: 'https://www.mijndomein.nl/energie',
    electricityAccentColor: '#6B2FAD',
    electricityProductionAccentColor: '#6B2FAD',
    gasAccentColor: '#6B2FAD',
    mobileDrawerAlign: 'right',
    energyCostAccentColor: '#6B2FAD',
    energyCostBusinessAccentColor: '#13C9B4',
    onboarding: false,

    /**
     * Mobile menu variants
     * 1. Drawer
     * 2. Page
     */
    mobileMenuVariant: 2,
    phoneNumber: '085 808 5143',
    appDomain: 'Mijndomein',
    vendorID: '83d5ced0-45e8-479a-9d70-9988d0997516',
    splitBusiness: true,
    myAccount: true,
    smartCharging: false,
    onboardingIsHidden: true,
    hideNewContractButton: true
};