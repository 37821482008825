'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  /**
   * Menu position variants
   * 1. Menu in left column
   * 2. Menu above columns
   */
  menuPositionVariant: 2,
  menuSelected: 2,
  title: 'Vul je gegevens in',
  vendor: 'Mijndomein Energie',
  submitButtonText: 'Doorgaan met bestellen',
  smartMeterChoiceNoSmartMeterAndDontWantDisabled: true,
  splitBusiness: true
};