'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _ButtonComponent = require('./components/button-component/ButtonComponent');

var _ButtonComponent2 = _interopRequireDefault(_ButtonComponent);

var _ModalComponent = require('./components/modal-component/ModalComponent');

var _ModalComponent2 = _interopRequireDefault(_ModalComponent);

var _TabsComponent = require('./components/tabs-component/TabsComponent');

var _TabsComponent2 = _interopRequireDefault(_TabsComponent);

var _DropdownSelectorComponent = require('./components/dropdown-selector-component/DropdownSelectorComponent');

var _DropdownSelectorComponent2 = _interopRequireDefault(_DropdownSelectorComponent);

var _PaginationComponent = require('./components/pagination-component/PaginationComponent');

var _PaginationComponent2 = _interopRequireDefault(_PaginationComponent);

var _NotificationBarComponent = require('./components/notification-bar-component/NotificationBarComponent');

var _NotificationBarComponent2 = _interopRequireDefault(_NotificationBarComponent);

var _CheckboxComponent = require('./components/checkbox-component/CheckboxComponent');

var _CheckboxComponent2 = _interopRequireDefault(_CheckboxComponent);

var _InputComponent = require('./components/input-component/InputComponent');

var _InputComponent2 = _interopRequireDefault(_InputComponent);

var _PlaceholderComponent = require('./components/placeholder-component/PlaceholderComponent');

var _PlaceholderComponent2 = _interopRequireDefault(_PlaceholderComponent);

var _GridComponent = require('./components/grid-component/GridComponent');

var _GridComponent2 = _interopRequireDefault(_GridComponent);

var _HvButton = require('./components/atoms/HvButton');

var _HvButton2 = _interopRequireDefault(_HvButton);

var _HvInput = require('./components/atoms/HvInput');

var _HvInput2 = _interopRequireDefault(_HvInput);

var _Forgot = require('./forms/Forgot');

var _Forgot2 = _interopRequireDefault(_Forgot);

var _Login = require('./forms/Login');

var _Login2 = _interopRequireDefault(_Login);

var _Reset = require('./forms/Reset');

var _Reset2 = _interopRequireDefault(_Reset);

var _MonthOverview = require('./containers/energy-splitter/MonthOverview');

var _MonthOverview2 = _interopRequireDefault(_MonthOverview);

var _WeekOverview = require('./containers/energy-splitter/WeekOverview');

var _WeekOverview2 = _interopRequireDefault(_WeekOverview);

var _ApproveModal = require('./containers/energy-splitter/ApproveModal');

var _ApproveModal2 = _interopRequireDefault(_ApproveModal);

var _AddButton = require('./containers/energy-splitter/AddButton');

var _AddButton2 = _interopRequireDefault(_AddButton);

var _EnergysplitterFirstTime = require('./containers/energy-splitter/EnergysplitterFirstTime');

var _EnergysplitterFirstTime2 = _interopRequireDefault(_EnergysplitterFirstTime);

var _EnergySplitter = require('./containers/energy-splitter/EnergySplitter');

var _EnergySplitter2 = _interopRequireDefault(_EnergySplitter);

var _CurrentPrices = require('./containers/current-prices/CurrentPrices');

var _CurrentPrices2 = _interopRequireDefault(_CurrentPrices);

var _vendor = require('./vendor.style');

var _vendor2 = _interopRequireDefault(_vendor);

var _settings = require('./app/settings');

var _settings2 = _interopRequireDefault(_settings);

var _settings3 = require('./calculation-tool/settings');

var _settings4 = _interopRequireDefault(_settings3);

var _settings5 = require('./customer-form/settings');

var _settings6 = _interopRequireDefault(_settings5);

var _settings7 = require('./dashboard/settings');

var _settings8 = _interopRequireDefault(_settings7);

var _settings9 = require('./dashboard-block-half/settings');

var _settings10 = _interopRequireDefault(_settings9);

var _settings11 = require('./electricity/settings');

var _settings12 = _interopRequireDefault(_settings11);

var _settings13 = require('./energy-costs/settings');

var _settings14 = _interopRequireDefault(_settings13);

var _settings15 = require('./forgot-password/settings');

var _settings16 = _interopRequireDefault(_settings15);

var _settings17 = require('./gas/settings');

var _settings18 = _interopRequireDefault(_settings17);

var _settings19 = require('./icons/settings');

var _settings20 = _interopRequireDefault(_settings19);

var _settings21 = require('./invoices/settings');

var _settings22 = _interopRequireDefault(_settings21);

var _settings23 = require('./lander/settings');

var _settings24 = _interopRequireDefault(_settings23);

var _settings25 = require('./menu-icons/settings');

var _settings26 = _interopRequireDefault(_settings25);

var _settings27 = require('./order-menu/settings');

var _settings28 = _interopRequireDefault(_settings27);

var _settings29 = require('./order-overview/settings');

var _settings30 = _interopRequireDefault(_settings29);

var _settings31 = require('./product-details/settings');

var _settings32 = _interopRequireDefault(_settings31);

var _settings33 = require('./productgroup-overview-display/settings');

var _settings34 = _interopRequireDefault(_settings33);

var _settings35 = require('./production/settings');

var _settings36 = _interopRequireDefault(_settings35);

var _settings37 = require('./product-list/settings');

var _settings38 = _interopRequireDefault(_settings37);

var _settings39 = require('./usage-template/settings');

var _settings40 = _interopRequireDefault(_settings39);

var _settings41 = require('./usage-template-tooltip/settings');

var _settings42 = _interopRequireDefault(_settings41);

var _settings43 = require('./user-settings-display/settings');

var _settings44 = _interopRequireDefault(_settings43);

var _settings45 = require('./user-settings-edit/settings');

var _settings46 = _interopRequireDefault(_settings45);

var _settings47 = require('./savings/settings');

var _settings48 = _interopRequireDefault(_settings47);

var _settings49 = require('./collective-competition/settings');

var _settings50 = _interopRequireDefault(_settings49);

var _settings51 = require('./screens/settings');

var _settings52 = _interopRequireDefault(_settings51);

var _settings53 = require('./scan-barcode/settings');

var _settings54 = _interopRequireDefault(_settings53);

var _settings55 = require('./guard/settings');

var _settings56 = _interopRequireDefault(_settings55);

var _defaults = require('./defaults.pcss');

var _defaults2 = _interopRequireDefault(_defaults);

var _globals = require('./globals.pcss');

var _globals2 = _interopRequireDefault(_globals);

var _style = require('./app/style.pcss');

var _style2 = _interopRequireDefault(_style);

var _style3 = require('./calculation-tool/style.pcss');

var _style4 = _interopRequireDefault(_style3);

var _style5 = require('./customer-form/style.pcss');

var _style6 = _interopRequireDefault(_style5);

var _style7 = require('./dashboard/style.pcss');

var _style8 = _interopRequireDefault(_style7);

var _style9 = require('./dashboard-block-half/style.pcss');

var _style10 = _interopRequireDefault(_style9);

var _style11 = require('./dropdown-menu/style.pcss');

var _style12 = _interopRequireDefault(_style11);

var _style13 = require('./epic-flow/style.pcss');

var _style14 = _interopRequireDefault(_style13);

var _style15 = require('./forgot-password/style.pcss');

var _style16 = _interopRequireDefault(_style15);

var _style17 = require('./invoices/style.pcss');

var _style18 = _interopRequireDefault(_style17);

var _style19 = require('./lander/style.pcss');

var _style20 = _interopRequireDefault(_style19);

var _style21 = require('./menu-items/style.pcss');

var _style22 = _interopRequireDefault(_style21);

var _style23 = require('./order-menu/style.pcss');

var _style24 = _interopRequireDefault(_style23);

var _style25 = require('./order-overview/style.pcss');

var _style26 = _interopRequireDefault(_style25);

var _style27 = require('./product-details/style.pcss');

var _style28 = _interopRequireDefault(_style27);

var _style29 = require('./product-list/style.pcss');

var _style30 = _interopRequireDefault(_style29);

var _style31 = require('./productgroup-overview-display/style.pcss');

var _style32 = _interopRequireDefault(_style31);

var _style33 = require('./radio-button/style.pcss');

var _style34 = _interopRequireDefault(_style33);

var _style35 = require('./radio-button-group/style.pcss');

var _style36 = _interopRequireDefault(_style35);

var _style37 = require('./reset-password/style.pcss');

var _style38 = _interopRequireDefault(_style37);

var _style39 = require('./spinner/style.pcss');

var _style40 = _interopRequireDefault(_style39);

var _style41 = require('./start-screens/style.pcss');

var _style42 = _interopRequireDefault(_style41);

var _style43 = require('./toast/style.pcss');

var _style44 = _interopRequireDefault(_style43);

var _style45 = require('./usage-template/style.pcss');

var _style46 = _interopRequireDefault(_style45);

var _style47 = require('./user-settings/style.pcss');

var _style48 = _interopRequireDefault(_style47);

var _style49 = require('./user-settings-display/style.pcss');

var _style50 = _interopRequireDefault(_style49);

var _style51 = require('./user-settings-edit/style.pcss');

var _style52 = _interopRequireDefault(_style51);

var _style53 = require('./savings/style.pcss');

var _style54 = _interopRequireDefault(_style53);

var _style55 = require('./collective-competition/style.pcss');

var _style56 = _interopRequireDefault(_style55);

var _style57 = require('./screens/style.pcss');

var _style58 = _interopRequireDefault(_style57);

var _style59 = require('./guard/style.pcss');

var _style60 = _interopRequireDefault(_style59);

var _settings57 = require('./toast/settings');

var _settings58 = _interopRequireDefault(_settings57);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// @ts-ignore-end

// vendor styles


// Forms
// Lit components
exports.default = {
    settings: {
        // Lit
        // Components
        'button-component': _ButtonComponent2.default,
        'modal-component': _ModalComponent2.default,
        'tabs-component': _TabsComponent2.default,
        'dropdown-selector-component': _DropdownSelectorComponent2.default,
        'pagination-component': _PaginationComponent2.default,
        'notification-component': _NotificationBarComponent2.default,
        'checkbox-component': _CheckboxComponent2.default,
        'input-component': _InputComponent2.default,
        'placeholder-component': _PlaceholderComponent2.default,
        'grid-component': _GridComponent2.default,

        // Atoms
        'hv-button': _HvButton2.default,
        'hv-input': _HvInput2.default,

        // Forms
        'ice-forgot': _Forgot2.default,
        'ice-login': _Login2.default,
        'ice-reset': _Reset2.default,

        // Componts energy splitter
        'energy-splitter-month-view': _MonthOverview2.default,
        'energy-splitter-week-overview': _WeekOverview2.default,
        'energy-splitter-add-button': _AddButton2.default,
        'energy-splitter-approve-modal': _ApproveModal2.default,

        // Containers
        'first-time-energysplitter': _EnergysplitterFirstTime2.default,
        'energy-splitter': _EnergySplitter2.default,
        'current-prices': _CurrentPrices2.default,

        // lit css
        'vendor-style': _vendor2.default,

        // Polymer
        'app': _settings2.default,
        'calculation-tool': _settings4.default,
        'customer-form': _settings6.default,
        'dashboard': _settings8.default,
        'dashboard-block-half': _settings10.default,
        'energy-costs': _settings14.default,
        'electricity': _settings12.default,
        'forgot-password': _settings16.default,
        'gas': _settings18.default,
        'icons': _settings20.default,
        'invoices': _settings22.default,
        'lander': _settings24.default,
        'menu-icons': _settings26.default,
        'order-menu': _settings28.default,
        'order-overview': _settings30.default,
        'product-details': _settings32.default,
        'productgroup-overview-display': _settings34.default,
        'production': _settings36.default,
        'product-list': _settings38.default,
        'usage-template': _settings40.default,
        'usage-template-tooltip': _settings42.default,
        'user-settings-display': _settings44.default,
        'user-settings-edit': _settings46.default,
        'savings': _settings48.default,
        'collective-competition': _settings50.default,
        'screens': _settings52.default,
        'scan-barcode': _settings54.default,
        'guard': _settings56.default,
        'ez-toast': _settings58.default
    },
    styles: {
        '_defaults': _defaults2.default,
        'globals': _globals2.default,
        'app': _style2.default,
        'calculation-tool': _style4.default,
        'customer-form': _style6.default,
        'dashboard': _style8.default,
        'dashboard-block-half': _style10.default,
        'dropdown-menu': _style12.default,
        'epic-flow': _style14.default,
        'forgot-password': _style16.default,
        'invoices': _style18.default,
        'lander': _style20.default,
        'menu-items': _style22.default,
        'order-menu': _style24.default,
        'order-overview': _style26.default,
        'product-details': _style28.default,
        'product-list': _style30.default,
        'productgroup-overview-display': _style32.default,
        'radio-button': _style34.default,
        'radio-button-group': _style36.default,
        'reset-password': _style38.default,
        'spinner': _style40.default,
        'start-screens': _style42.default,
        'ez-toast': _style44.default,
        'usage-template': _style46.default,
        'user-settings': _style48.default,
        'user-settings-display': _style50.default,
        'user-settings-edit': _style52.default,
        'savings': _style54.default,
        'collective-competition': _style56.default,
        'screens': _style58.default,
        'guard': _style60.default
    },
    vendors: [{
        test: /.*/,
        apiUrl: 'https://api.energyzero.nl',
        appUrl: 'https://energie.mijndomein.nl',
        vendorId: '83D5CED0-45E8-479A-9D70-9988D0997516',
        gtagId: 'GTM-5TRD8TG',
        title: 'Mijndomein Energie'
    }, {
        test: /mijndomein.frontend.staging.energyzero.nl/,
        apiUrl: 'https://api.energyzero.nl',
        appUrl: 'https://mijndomein.frontend.staging.energyzero.nl',
        vendorId: '83D5CED0-45E8-479A-9D70-9988D0997516',
        gtagId: 'GTM-5TRD8TG',
        title: 'Mijndomein Energie'
    }]
};

// Polymer components


// Lit Pages


// Lit Energysplitter components