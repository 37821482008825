'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    getDataString: type => {
        switch (type) {
            case 'production':
                return 'Teruggeleverd aan\nhet stroomnet';
            case 'coins':
                return 'Verbruik in\nenergiekosten';
            default:
                return 'Verbruikt van het\n%snet';
        }
    },
    getNoDataString: type => {
        switch (type) {
            case 'production':
                return 'Stroommeter nog niet gekoppeld';
            case 'coins':
                return 'Meter(s) nog niet gekoppeld';
            case 'stroom':
                return 'Stroommeter nog niet gekoppeld';
            case 'gas':
                return 'Gasmeter nog niet gekoppeld';
            default:
                return '%s';
        }
    }
};