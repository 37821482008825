"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_element_1 = require("lit-element");
exports.default = lit_element_1.css `
    /* vendor root */
    :root {
        /* Page */
        --page-position: left;
        --page-header-height: 64px;
        --background-color: rgb(245, 255, 245);

        /* Colors */
        --primary-color: #6b2fad;
        --secondary-color: #aaa;

        /* Fonts */
        --font-size: 16px;
        --font-color: #444;
        --font-secondary-color: #999;

        /* Links */
        --link-color: green;
        --link-hover-color: red;

        /* Devider */
        --divider--color: #444;

        /* Border */
        --border-radius: var(--border-radius);
    }
`;
