'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  /**
   * Info line variants
   * 1. Info lines in component wrapper
   * 2. Info lines on bottom
   */
  infoLineVariant: 2,
  /**
   * Scope control variants
   * 1. Scope control on bottom
   * 2. Scope control on top
   * 3. Scope control in component wrapper
   */
  scopeControlVariant: 2,
  showStartDateLine: true,
  showYearScope: true,
  startDateTransform: (dateString, rawDate) => rawDate < Date.now() ? `De energielevering is op ${dateString} gestart` : `De energielevering start op ${dateString}`,
  disclaimer: 'Aan deze informatie kunnen geen rechten worden ontleend i.v.m. eventuele correcties in verbruiksdata en -kosten.'
};