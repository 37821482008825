"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    // styles
    label: {
        submit: 'Verstuur aanvraag',
    },
    showLabel: true,
    styleObject: {
        display: 'block',
    },
};
