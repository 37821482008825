'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    chartThemePrimaryColor: '#6B2FAD',
    chartThemeSecondaryColor: '#C0A6DC',
    chartThemeSecondaryLineColor: '#95A4AC',
    chartThemePrimayLineTension: 0.4,
    chartThemeSecondaryLineTension: 0.4
};