'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  /**
   * Style injector for product panels
   * @param {Number} productSets the amount of product sets on screen
   * @param {Number} configurations the amount of configurations on screen
   * @return {String}
   */
  panelStyling: (productSets, configurations) => configurations === 1 ? `flex-basis: 480px;` : '',
  // Example (2, 4, 3) => 'flex-basis: 64%;'

  /**
   * Set filter based on product names
   * @param {Object} switches
   * @return {Array}
   */
  productSetFilterByName: ({ showFlex }) => showFlex ? ['vast', 'model'] : ['Flexibel per maand', 'model'],
  // Example ({showFlex: false}) => ['flex', 'model'] hides all products with 'model' or 'flex' in the name

  /**
   * The selection details to show under the product, the order and the options
   */
  selectionDetails: [{
    type: 'ADDRESS'
  }, {
    allowEditMeterCapacity: true,
    title: 'Stroom',
    type: 'ELECTRICITY',
    valueTransform: value => `${value}kWh`
  }, {
    allowEditMeterCapacity: true,
    title: 'Gas',
    type: 'GAS',
    valueTransform: value => `${value}m³`
  }, {
    title: 'Teruglevering',
    type: 'ELECTRICITY_PRODUCTION',
    valueTransform: value => `${value}kWh`
  }],

  /**
   * The string to display above the information specified by the user
   */
  specificationString: 'Het aanbod is geldig voor',
  bannerTitleString: 'Jouw maandvoordeel',
  bannerSubTitleString: 'Ga je voor vaste of flexibele energieprijzen en looptijden',
  /**
   * Generates top switch elements
   */
  topSwitches: {
    /**
     * Create bottom switch with name: 'showModule'
     */
    showFlex: {
      /**
       * Default switch state is false
       */
      default: true,

      /**
       * The string to display when off
       */
      off: 'Vast',

      /**
       * The string to display when on
       */
      on: 'Flexibel',

      /**
       * Switch variants
       * 1. Toggle switch (default)
       * 2. Toggle button
       */
      variant: 1
    }
  },

  /**
   * Generates top anchors for scrollable contracts layout
   * Mobile only option
   */
  topAnchors: {
    /**
     * Default enabled state is false
     */
    enabled: false,
    /**
     * Anchors settings
     */
    settings: {
      combineProductGroups: false
    }
  },

  /**
   * Variants
   * 1. Products in panels with options inside
   * 2. Options in panels
   */
  variant: 2,

  /**
   * variant description
   */
  vastDescription:
  // eslint-disable-next-line max-len
  'Wil je liever genieten van zekerheid, klik dan hier en ontdek jouw aanbod voor een vast contract met stabiele, lage energiekosten. Bij Mijndomein Energie kun je jouw stroom- en gasprijzen nu voor één, drie en zelfs voor vijf jaar vastzetten. Uiteraard tegen de huidige, scherpe inkoopprijzen, zonder opslag dus. Zo weet je voor een langere periode waar je aan toe bent. Best fijn!',
  flexDescription:
  // eslint-disable-next-line max-len
  'Ga je liever voor flexibel, klik dan hier. Onderzoek laat zien dat flex tarieven vaak goedkoper zijn. Met Flex weet je ook van tevoren wat de stroomprijzen van morgen zijn, per uur. Zo kun je je wasmachine aanzetten op de goedkoopste momenten. Een Flex contract is op elk moment om te zetten in een Vast contract, bijvoorbeeld wanneer de energieprijzen weer gaan stijgen.'
};