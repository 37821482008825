"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    tooltipOffset: () => {
        if (window.innerWidth <= 810) {
            return 49;
        } else if (window.innerWidth <= 1100) {
            return 79;
        }
        return 349;
    }
};